import { Api } from "@/libs/api";
import {
  Events,
  DownloadLimitNearEventPayload,
  PubSubProvider,
} from "@/libs/pubSub";

export const checkDownloadCount = () => {
  Api.getUserData().then((data) => {
    const { currentDownloads, totalAvailableDownloads, warningLimit } = data;
    if (currentDownloads >= totalAvailableDownloads) {
      PubSubProvider.publishEvent<{}>(Events.DownloadLimitReached, {});
      return true;
    }

    if (totalAvailableDownloads - warningLimit <= currentDownloads) {
      PubSubProvider.publishEvent<DownloadLimitNearEventPayload>(
        Events.DownloadLimitNear,
        {
          currentDownloads,
          totalAvailableDownloads,
        }
      );
      return true;
    }
  });
};
