import React, { useEffect } from "react";
import {
  Container,
  ISearchBlockCtaProps,
  SearchBlock,
} from "@lions/ui-components";
import { SectionComponent } from "./SectionComponent";
import { AiSearchBarData, SectionBlockData } from "./components.d";
import { Events, PubSubProvider } from "@/libs/pubSub";
import { Api } from "@/libs/api";

export const AiSearchBarComponent = ({ searchText }: AiSearchBarData) => {
  useEffect(() => {
    if (searchText) {
      const formattedText = Array.isArray(searchText)
        ? searchText.join(" ")
        : searchText;
      askAiCallback(formattedText);
    }
  }, [searchText]);

  return (
    <Container>
      <SectionComponent sectionData={sectionDark}>
        <SearchBlock focus={true} cta={askCta} callback={askAiCallback} />
      </SectionComponent>
    </Container>
  );
};

const askAiCallback = (searchText: string): string => {
  Api.askAi(searchText)
    .then((response) => {
      if (response) {
        PubSubProvider.publishEvent(Events.AISearch, response);
      }
    })
    .catch((error) => {
      console.error("Error in askAiCallback:", error);
    });

  return ""; // Always return a string immediately
};

const askCta: ISearchBlockCtaProps = {
  label: "Ask",
  icon: { name: "ChevronRight" },
};

const sectionDark = {
  type: "SectionBlock",
  backgroundMode: "dark",
  darkModeBackgroundColour: "black",
} as SectionBlockData;
