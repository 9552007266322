import { TableDataNew } from "./components.d";
import { Table, AwardTag } from "@lions/ui-components";
import { HeroTitle, Spacer } from "@horizon/components";

export const TableComponentNew = (
  tableDataLocal: TableDataNew
): JSX.Element => {
  const {
    tableData: { rows },
  } = tableDataLocal;
  if (rows?.length) {
    const lastRowCell: any = rows[0].cells[4];
    if (lastRowCell && lastRowCell.tag) {
      lastRowCell.tag.component = AwardTag;
    }
  }

  return (
    <div data-panda-theme="intelligence" className="table-over-rides">
      <Spacer space="large">
        {tableDataLocal.title && (
          <HeroTitle size={["xsmall", "medium"]} as="h2">
            {tableDataLocal.title.toUpperCase()}
          </HeroTitle>
        )}
        <Table {...tableDataLocal.tableData} />
      </Spacer>
    </div>
  );
};
