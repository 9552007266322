import React, { useState } from "react";
import { Container, Typography } from "@lions/ui-components";
import { SectionBlockData } from "./components.d";
import { SectionComponent } from "./SectionComponent";
import { TypographyColor } from "./TypographyColor";
import { AskResponse } from "@/services/providers/llm/types";
import { Events, PubSubProvider } from "@/libs/pubSub";

export const AiResponseComponent = () => {
  const [response, setResponse] = useState<AskResponse[]>([]);

  PubSubProvider.useSub(Events.AISearch, (askPayload: AskResponse) => {
    setResponse([...response, askPayload]);
  });
  return response.length > 0 ? (
    <Container>
      <TypographyColor color="black" size="heading-large">
        Answers
      </TypographyColor>
      <SectionComponent sectionData={section}>
        {response &&
          response.map((response) => (
            <Typography key={response.responseId}>
              {response.content}
            </Typography>
          ))}
      </SectionComponent>
    </Container>
  ) : (
    <></>
  );
};

const section = {
  type: "SectionBlock",
  backgroundMode: "light",
} as SectionBlockData;
