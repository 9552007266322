import {
  Box,
  Flex,
  SearchStringMatch,
  Typography,
  Button,
} from "@lions/ui-components";
import {
  PubSubProvider,
  Events,
  SearchTextChange,
  SuggestionSelectedI,
} from "@/libs/pubSub";
import React, { useEffect, useState } from "react";
import { Spacer } from "@horizon/components";
import { Api } from "@/libs/api";
import {
  SearchSuggestion,
  SearchHistory,
} from "@/services/providers/gemini/types";
import { useDebounce } from "@/libs/hooks/useDebounce";

export const SearchBarSuggestions = () => {
  const [suggestionText, setSuggestionText] = useState<string>("");
  const [contentType, setContentType] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<
    SearchSuggestion[]
  >([]);
  const [searchHistories, setSearchHistory] = useState<SearchHistory[]>([]);
  const [currentSuggestion, setCurrentSearchText] = useState("");
  const [selectedSuggestion, setSelectedSuggestions] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  PubSubProvider.useSub(Events.SearchTextChange, (result: SearchTextChange) => {
    setSuggestionText(result.searchText);
    setContentType(result.contentType);
  });

  useEffect(() => {
    if (
      !isSubmitting &&
      suggestionText.length > 2 &&
      selectedSuggestion &&
      selectedSuggestion !== "" &&
      searchSuggestions.length > 0
    ) {
      PubSubProvider.publishEvent<SuggestionSelectedI>(
        Events.SuggestionSelected,
        {
          suggestionSelected: selectedSuggestion,
        }
      );
      setIsSubmitting(true);
      setSearchSuggestions([]);
      setSearchHistory([]);
      setSelectedSuggestions("");
      setCurrentSearchText(selectedSuggestion);
    }
  }, [
    selectedSuggestion,
    setSearchSuggestions,
    setSearchHistory,
    setIsSubmitting,
    isSubmitting,
    searchSuggestions,
    suggestionText,
  ]);

  const getSearchSuggestions = async (text: string) => {
    try {
      setCurrentSearchText(text);
      const suggestions: SearchSuggestion[] = await Api.getSearchSuggestions({
        searchText: text,
        contentTypes: [contentType],
      });
      const histories: SearchHistory[] = await Api.getSearchHistory();

      // prevents displaying of data when user is still typing
      if (
        suggestionText.length > 2 &&
        suggestions.length &&
        suggestions[0].searchText === suggestionText
      ) {
        setSearchSuggestions(suggestions);
        setSearchHistory(histories);
      }
    } catch (error) {
      setSearchSuggestions([]);
    }
  };

  const debounced = useDebounce(getSearchSuggestions, 250);

  useEffect(() => {
    if (suggestionText.length >= 3 && currentSuggestion !== suggestionText) {
      debounced(suggestionText);
    }

    if (
      suggestionText &&
      suggestionText.length < 3 &&
      searchSuggestions.length !== 0
    ) {
      setSearchSuggestions([]);
    }
  }, [
    suggestionText,
    debounced,
    setSearchSuggestions,
    currentSuggestion,
    searchSuggestions,
  ]);

  const searchAll = () => {
    PubSubProvider.publishEvent<SuggestionSelectedI>(
      Events.SuggestionSearchAll,
      {
        suggestionSelected: suggestionText,
      }
    );
  };

  if (!isSubmitting && searchSuggestions.length && suggestionText.length > 2) {
    return (
      <Flex
        direction="column"
        pb="spacing-large"
        py="spacing-xxlarge"
        gap="spacing-xsmall"
      >
        {searchSuggestions?.map((suggestion: SearchSuggestion) => {
          return (
            <Box className="search-suggest-override" key={suggestion.fieldText}>
              <SearchStringMatch
                onClick={() => {
                  setSelectedSuggestions(suggestion.fieldText);
                }}
                searchString={suggestionText}
                text={`Search ${suggestion.fieldText}`}
              />
            </Box>
          );
        })}

        {searchSuggestions && searchHistories && (
          <Box className="search-history-box" pt="spacing-xxsmall"></Box>
        )}

        {searchHistories?.map((history: SearchHistory) => {
          return (
            <Box className="search-suggest-override" key={history.searchText}>
              <SearchStringMatch
                iconName="History"
                onClick={() => {
                  setSelectedSuggestions(history.searchText);
                }}
                searchString={suggestionText}
                text={`Search ${history.searchText}`}
              />
            </Box>
          );
        })}
        <Spacer space="large" className="search-history-box-bottom">
          <Flex>
            <Button variant="linkInteractive" onClick={searchAll}>
              View all results
            </Button>
            <Typography as="span">
              &nbsp; for &lsquo;{suggestionText}&rsquo;
            </Typography>
          </Flex>
        </Spacer>
      </Flex>
    );
  }
  return null;
};
