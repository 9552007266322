import { useEffect } from "react";
import { EventEmitter } from "eventemitter3";
import { Event } from "./";

const emitter = new EventEmitter();

const useSub = (event: Event, callback: any) => {
  useEffect(() => {
    emitter.on(event, callback);
    return () => {
      emitter.off(event, callback);
    };
  }, [callback, event]);
};

const publishEvent = <Type,>(event: Event, data: Type) => {
  emitter.emit(event, data);
};

export const PubSubProvider = Object.freeze({ useSub, publishEvent });
