import { searchContent } from "@/libs/search";
import { PubSubProvider, Events, SearchQueryResponse } from "@/libs/pubSub";
import { LoadMoreButtonData, PaginationData } from "@/renderers";
import { ScrollButton } from "@lions/ui-components";
import { useState } from "react";
import { QueryParams } from "@/libs/QueryParams";
import { combineQueryParams } from "@/libs/QueryParams/query_params";

export const LoadMoreButton = ({
  ctaText,
  ariaText,
  pagination,
  searchQuery,
}: LoadMoreButtonData) => {
  const [currentPage, setCurrentPage] = useState(pagination?.page || 1);
  const [isLoading, setLoading] = useState(false);
  const currentCount = calculateCurrentCount(pagination);

  PubSubProvider.useSub(
    Events.SearchResults,
    (results: SearchQueryResponse) => {
      setLoading(false);
      setCurrentPage(currentPage + 1);
    }
  );
  if (!hasMoreItems(pagination)) return null;

  return (
    <ScrollButton
      ariaLabel={ariaText || "Load More Results"}
      ctaText={ctaText || `Load ${pagination?.pageSize} More`}
      currentCount={currentCount}
      onClick={() => {
        setLoading(true);
        const queryParams = combineQueryParams(
          searchQuery + `&page=${currentPage + 1}`,
          QueryParams.currentSearchQuery()
        );
        searchContent({
          parsedSearchQuery: queryParams,
        });
      }}
      totalCount={pagination?.totalRecords || 0}
      isLoading={isLoading}
    />
  );
};

const hasMoreItems = (pagination: PaginationData | undefined) => {
  if (
    pagination &&
    pagination.totalRecords &&
    pagination.pageSize &&
    pagination.page
  ) {
    return pagination.totalRecords > pagination.page * pagination.pageSize;
  }
  return false;
};

const calculateCurrentCount = (pagination?: PaginationData) => {
  if (pagination && pagination.page && pagination.pageSize)
    return pagination?.page * pagination?.pageSize;
  return 0;
};
