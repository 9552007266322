import { Dispatch, SetStateAction, useState } from "react";
import { Modal_v2 as Modal, MarkdownRenderer } from "@horizon/components";
import { PATHS } from "@/services/libs";
import {
  DownloadLimitNearEventPayload,
  Events,
  PubSubProvider,
} from "@/libs/pubSub";

export interface NotificationState {
  isVisible: boolean;
  title: string;
  body: string;
}

const handleDownloadLimitReached = (
  setNotificationState: Dispatch<SetStateAction<NotificationState>>
) => {
  setNotificationState({
    isVisible: true,
    title: "Download Limit Reached",
    body: "You have reached the maximum number of downloads permitted. For more information please see our Terms & Conditions.",
  });
};

const handleDownloadLimitNear = (
  setNotificationState: Dispatch<SetStateAction<NotificationState>>,
  { currentDownloads, totalAvailableDownloads }: DownloadLimitNearEventPayload
) => {
  const remainingDownloads = totalAvailableDownloads - currentDownloads;
  setNotificationState({
    isVisible: true,
    title: "Nearing download limit",
    body: `You have downloaded ${currentDownloads} times this month and have ${remainingDownloads} downloads remaining on your account. For more information please see our Terms & Conditions.`,
  });
};

export const Notification = (): JSX.Element | null => {
  const [notificationState, setNotificationState] = useState({
    isVisible: false,
    title: "",
    body: "",
  });

  PubSubProvider.useSub(Events.DownloadLimitReached, () => {
    handleDownloadLimitReached(setNotificationState);
  });

  PubSubProvider.useSub(
    Events.DownloadLimitNear,
    (payload: DownloadLimitNearEventPayload) => {
      handleDownloadLimitNear(setNotificationState, payload);
    }
  );

  const closeModal = () => {
    setNotificationState({
      isVisible: false,
      title: "",
      body: "",
    });
  };

  const viewTerms = () =>
    typeof window !== "undefined" &&
    (window.location.href = PATHS.termsAndConditions);

  const { isVisible, title, body } = notificationState;

  return (
    <>
      {isVisible && (
        <Modal
          isOpen={isVisible}
          closeModal={() => {
            closeModal();
          }}
          canClose={true}
          title={{
            as: "h4",
            copy: title,
          }}
          ctas={[
            {
              children: "Close",
              onClick: closeModal,
              variant: "secondary",
            },
            {
              children: "View T&Cs",
              onClick: viewTerms,
              variant: "primary",
            },
          ]}
        >
          <MarkdownRenderer html={body} />
        </Modal>
      )}
    </>
  );
};
