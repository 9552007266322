import { SearchEvents } from "./search";
import { DownloadEvents } from "./downloads";
import { AIEvents } from "./ai";

export const Events = Object.freeze({
  ...AIEvents,
  ...SearchEvents,
  ...DownloadEvents,
} as const);

type EventKeys = keyof typeof Events;
export type Event = (typeof Events)[EventKeys];
