import { createId } from "@/components/libs";
import {
  BrandColour,
  ComponentData,
  ContainerData,
  SectionBlockData,
  SpacerData,
} from "@/renderers";

type MaybeComponentData = ComponentData | undefined;
type MaybeArray<T> = T | T[];

const maybeMakeArray = <T,>(item: T | T[]): T[] =>
  Array.isArray(item) ? item : [item];

export const buildDarkSectionData = (
  content: MaybeArray<MaybeComponentData>,
  title?: string,
  backgroundColor?: BrandColour
): SectionBlockData => ({
  type: "SectionBlock",
  backgroundMode: "dark",
  darkModeBackgroundColour: backgroundColor ?? "primary",
  content: maybeMakeArray(content),
  title,
  id: createId(title),
});

export const buildSectionData = (
  content: MaybeArray<MaybeComponentData>,
  title?: string,
  backgroundColor?: BrandColour
): SectionBlockData => ({
  type: "SectionBlock",
  backgroundMode: "light",
  darkModeBackgroundColour: backgroundColor,
  content: maybeMakeArray(content),
  title,
  id: createId(title),
});

export const buildContainerData = (
  children: MaybeArray<MaybeComponentData>
): ContainerData => ({
  type: "Container",
  children: maybeMakeArray(children),
});

export const buildSpacerData = (
  content: MaybeArray<MaybeComponentData>,
  size: "small" | "medium" | "large" = "medium"
): SpacerData => ({
  type: "Spacer",
  size,
  content: maybeMakeArray(content),
});
