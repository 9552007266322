import { callClientApi } from "@/libs/client_api_caller";
import { AskResponse } from "@/services/providers/llm/types";

const ASK_API_URL = "/api/ai/ask";

export const askAi = async (searchText: string) => {
  const response = await callClientApi(ASK_API_URL, { question: searchText })
    .then((nextResponse) => {
      if (!nextResponse.ok) {
        console.error("Failed to fetch response from Ask API");
        return null;
      }
      return nextResponse.json() as Promise<AskResponse>;
    })
    .catch((error) => {
      console.error("Error while calling Ask API:", error);
      return null;
    });
  return response;
};
